:root {
  --primary-color: #000000;
  --secondary-color: #ffffff;
  --brand-color: $green;
  --header-back-color: #ffffff;
  --footer-back-color: #212121;
  --header-color: #000000;
  --footer-color: #FFFFFF;
}

$lg-max-width: 1440px;
$md-max-width: 1024px;
$sm-max-width: 768px;
$xs-max-width: 320px;

$header-height: 80px;
$footer-height: 80px;

$primary-color: var(--primary-color);
$secondary-color: var(--secondary-color);

$brand-color: var(--brand-color);

$header-back-color: var(--header-back-color);
$header-color: var(--header-color);
$footer-back-color: var(--footer-back-color);
$footer-color: var(--footer-color);

$body-background-color: #f7f7f7;
$grey6F: #6f6f6f;
$greyA6: #a6a6a6;
$greyEE: #eeeeee;
$greyDA: #dadada;

$red-error: #d20707;
$orange-warning: #fa6400;
$green: #179103;
$link: #3958a9;

.page-content-wrapper {
  padding-top: 80px;
  min-height: calc(100vh - $header-height);
}

body {
  background-color: $body-background-color;
  overflow-x: hidden;

  &.modal-open {
    overflow: hidden;
  }
}

app-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.1);
  // background-color: $secondary-color;
  background-color: var(--header-back-color);
  color: var(--header-color);
  z-index: 3;
}

app-footer {
  width: 100%;
  // background-color: $secondary-color;
  background-color: var(--footer-back-color);
  color: var(--footer-color);
  display: flex;
}

button {
  @include focusable;

  &.btn {
    border-radius: 2px;
    border: 1px solid $primary-color;
    height: 40px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    font-weight: 700;
    text-transform: uppercase;

    &.btn-primary {
      background-color: $primary-color;

      &:hover {
        border-color: $primary-color !important;
        background-color: $primary-color !important;
      }
    }

    &.btn-transparent {
      background-color: transparent;

      &:hover {
        border-color: $primary-color !important;
        background-color: transparent !important;
      }
    }

    &.btn-img {
      border: none;
      height: auto;
      padding: 0;
      background-color: transparent !important;

      &:hover {
        border: none !important;
        background-color: transparent !important;
      }
    }

    &.btn-link {
      border: none;
      height: auto;
      padding: 0;
      background-color: transparent !important;
      color: $link;
      text-decoration: underline;
      text-underline-offset: 2pt;

      &:hover {
        border: none !important;
        background-color: transparent !important;
      }
    }
  }
}

input[type="text"],
input[type="email"],
input[type="password"] {
  @include focusable;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid black;
  @extend .font-body;
}

input[type="password"] {
  padding-right: 40px;
}

input[type="password"]~button,
input[type="text"]~button {
  position: absolute;
  right: 10px;
  top: 50%;
  font-size: 12px;

  &.active {
    // color: green;
    color: $brand-color;
  }
}

.form-control:focus {
  border: none !important;
  border-bottom: 1px solid $primary-color !important;
  box-shadow: none;
  outline: none;
}

.form-floating {
  max-height: 58px;
  margin-bottom: 45px;
}

.form-floating>.form-label {
  padding-bottom: 10px !important;
  display: flex;
  align-items: flex-end;
  max-height: 58px;
  @extend .font-body;

  &::after {
    background-color: transparent !important;
    border-radius: 0 !important;
  }
}

.form-floating>.form-control:focus~label {
  transform: scale(1) translateY(-1.3rem) translateX(-0.7rem);
}

.form-floating>.form-control:not(:placeholder-shown)~label {
  transform: scale(1) translateY(-1.3rem) translateX(-0.7rem);
}

.form-floating>div.ctrl-error-container {
  padding-left: 12px;

  >span {
    @extend .font-caption-2;
    color: $red-error !important;
  }
}

.toggler-checkbox {
  display: flex;
  align-items: center;

  >span {
    @extend .font-body;
  }

  .toggler-checkbox-container {
    &:focus {
      @include focus-style;
    }

    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: solid 1px $grey6F;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    margin-right: 5px;

    &:hover {
      background-color: $greyEE;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
      background-color: $greyDA;
    }

    .toggler-checkbox-input {
      position: absolute;
      opacity: 0;
    }

    .ico-soft {
      display: none;
      color: $secondary-color;
    }

    .toggler-checkbox-input:checked~.ico-soft {
      display: block;
    }

    input {
      display: none;
    }
  }

  &.on {
    .toggler-checkbox-container {
      background-color: $primary-color;

      .sr-only {
        color: $secondary-color;
      }
    }
  }
}

.tooltip-info {
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.11);
  position: absolute;
  width: 224px;
  top: -33px;
  left: 20px;
  border-left: 2px solid $greyA6;
  background: $secondary-color;
  padding: 14px;
  // line-height: 14px;
  color: $primary-color;
  z-index: 2;
  @extend .font-caption-2;

  &.show {
    display: block;
  }

  @include media-breakpoint-down(sm) {
    top: 25px;
    left: calc((224px / 2) * -1);
    border-left: 0;
    border-top: 2px solid $greyA6;
  }

  &:before {
    content: "";
    position: absolute;
    left: -6px;
    top: 50%;
    width: 0;
    height: 0;
    border-right: 4px solid $greyA6;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;

    @include media-breakpoint-down(sm) {
      top: -10px;
      left: 50%;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 4px solid $greyA6;
    }
  }

  &.down {
    top: 25px;
    left: calc((-224px / 2) + 10px);
    border-left: 0;
    border-top: 2px solid $greyA6;

    &:before {
      top: -10px;
      left: calc(50% - 4px);
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 4px solid $greyA6;
    }
  }
}

.page-container {
  padding-top: $header-height;
  min-height: calc(100vh - $footer-height);
}

.modal {
  height: 100% !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.7);

  &.show {
    display: flex;
  }

  .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 636px;
    max-width: 636px;

    @include media-breakpoint-down(xl) {
      min-width: 610px;
      max-width: 610px;
    }

    @include media-breakpoint-down(lg) {
      min-width: 570px;
      max-width: 570px;
    }

    @include media-breakpoint-down(md) {
      min-width: 400px;
      max-width: 400px;
    }

    @include media-breakpoint-down(sm) {
      min-width: 288px;
      max-width: 288px;
      margin-left: auto !important;
      margin-right: auto !important;
    }

    // &.choose-brand-modal {
    //   min-width: 1076px;
    //   max-width: 1076px;

    //   @include media-breakpoint-down(xl) {
    //     min-width: 770px;
    //     max-width: 770px;
    //   }

    //   @include media-breakpoint-down(lg) {
    //     min-width: 570px;
    //     max-width: 570px;
    //   }

    //   @include media-breakpoint-down(md) {
    //     min-width: 400px;
    //     max-width: 400px;
    //   }

    //   @include media-breakpoint-down(sm) {
    //     min-width: 288px;
    //     max-width: 288px;
    //     margin-left: auto !important;
    //     margin-right: auto !important;
    //   }
    // }

    .modal-content {
      border: none !important;
      border-radius: 2px !important;
      padding: 40px !important;

      @include media-breakpoint-down(sm) {
        padding-left: 24px !important;
        padding-right: 24px !important;
      }

      button {
        &.close {
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $primary-color;
          font-size: 16px;
          position: absolute;
          right: 25px;
          top: 25px;
        }
      }

      .modal-header {
        position: relative;
        border: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
        // padding-left: 35px;
        // padding-right: 35px;
        padding: 0 !important;

        // button {
        //   &.close {
        //     width: 16px;
        //     height: 16px;
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        //     color: $primary-color;
        //     font-size: 16px;
        //     position: absolute;
        //     right: 16px;
        //     top: calc(50% - 10px);
        //   }
        // }
      }

      .modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // padding: 0 !important;
        margin: 24px 0 80px;

        .icon {
          min-width: 40px;
          min-height: 40px;
          font-size: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;

          &.alert {
            color: $orange-warning;
            padding: 0 !important;
          }

          &.error {
            color: $red-error;
            padding: 0 !important;
          }
        }
      }

      .modal-footer {
        border: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 !important;

        button {
          margin: 0 10px !important;
          min-width: 90px;
          justify-content: center;
        }
      }
    }
  }
}