/* You can add global styles to this file, and also import other style files */
// @import "../node_modules/bootstrap/scss/functions";
// @import "../node_modules/bootstrap/scss/variables";
// @import "../node_modules/bootstrap/scss/mixins";
// @import "../node_modules/bootstrap/scss/maps";
// @import "../node_modules/bootstrap/scss/utilities";

@import "bootstrap/scss/mixins";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/utilities";

@import "./app/styles/mixins.scss";
@import "./app/styles/_font_families.scss";
@import "./app/styles/fonts.scss";
@import "./app/styles/commons.scss";
// @import "./app/styles/brands-colors.scss";
// @import "./app/styles/global-styles.scss";

@import "./app/styles/_icon_font.scss";
