@font-face {
  font-family: "cws-icon";
  src: url("../../assets/fonts/cws-icon.ttf") format("truetype"),
    url("../../assets/fonts/cws-icon.woff") format("woff"),
    url("../../assets/fonts/cws-icon.svg") format("svg"),
    url("../../assets/fonts/cws-icon.eot") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
  // font-display: block;
}

@mixin font-ico {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "cws-icon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

[class^="ico-"],
[class*=" ico-"] {
  @include font-ico;

  &.ico-spin {
    animation: spinning 2s linear infinite;
    display: inline-block;
  }
}

.ico-icons-menu-offer:before {
  content: "\e9d1";
  color: #179103;
}

.ico-payment-ach:before,
.ico-ach:before {
  content: "\e9d0";
}

.ico-payment-card:before {
  content: "\e9cd";
}

.ico-payment-sepa:before {
  content: "\e9ce";
}

.ico-heart:before {
  content: "\e9cc";
}

.ico-diamond:before,
.ico-shape:before {
  content: "\e9ca";
}

.ico-wrench:before {
  content: "\e9cb";
}

.ico-shape:before {
  content: "\e9ca";
}

.ico-front-car:before {
  content: "\e9c9";
}

.ico-spinner:before {
  content: "\e9c8";
}

.ico-like:before {
  content: "\e9c6";
}

.ico-badge:before {
  content: "\e9c7";
}

.ico-flash-round:before {
  content: "\e9c4";
  color: #636363;
}

.ico-flash:before {
  content: "\e9c5";
  color: #636363;
}

.ico-alert-circle:before {
  content: "\e9c3";
}

.ico-boundry:before {
  content: "\e9be";
}

.ico-clock:before {
  content: "\e9bf";
}

.ico-exchange-arrows:before {
  content: "\e9c0";
}

.ico-speed:before {
  content: "\e9c1";
}

.ico-valet:before {
  content: "\e9c2";
}

.ico-social:before {
  content: "\e9bd";
}

.ico-social:before {
  content: "\e9bd";
  color: #000;
}

.ico-website-preferences .path1:before {
  content: "\e9b9";
  color: rgb(0, 0, 0);
}

.ico-website-preferences .path2:before {
  content: "\e9ba";
  margin-left: -2em;
  color: rgb(255, 255, 255);
}

.ico-website-preferences .path3:before {
  content: "\e9bb";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.ico-health:before {
  content: "\e96d";
}

.ico-externallink:before {
  content: "\e96c";
}

.ico-document:before {
  content: "\e904";
}

.ico-vehicle-info_odometer:before {
  content: "\e946";
}

.ico-vehicle-info_oil:before {
  content: "\e947";
}

.ico-vehicle-info_tire-pressure:before {
  content: "\e94b";
}

.ico-vehicle-info_wheel:before {
  content: "\e94c";
}

.ico-vehicle-status:before {
  content: "\e94d";
}

.ico-vhr_air-bag:before {
  content: "\e94e";
}

.ico-vhr_antilock:before {
  content: "\e950";
}

.ico-vhr_blind-spot-monitoring:before {
  content: "\e951";
}

.ico-vhr_brake-light:before {
  content: "\e952";
}

.ico-vhr_brake-warning-light:before {
  content: "\e953";
}

.ico-vhr_charhing-system:before {
  content: "\e954";
}

.ico-vhr_diesel-exhaust:before {
  content: "\e955";
}

.ico-vhr_diesel-filter:before {
  content: "\e956";
}

.ico-vhr_fog-lamp:before {
  content: "\e957";
}

.ico-vhr_fuel-cap-indicator:before {
  content: "\e958";
}

.ico-vhr_headlight:before {
  content: "\e959";
}

.ico-vhr_hight-beam-light:before {
  content: "\e95a";
}

.ico-vhr_hot-oil:before {
  content: "\e95b";
}

.ico-vhr_low-beam-loght:before {
  content: "\e95c";
}

.ico-vhr_tail-light:before {
  content: "\e95d";
}

.ico-vhr_transmission-temperature:before {
  content: "\e95e";
}

.ico-vhr_water-in-fuel:before {
  content: "\e95f";
}

.ico-unlock:before {
  content: "\e960";
}

.ico-start:before {
  content: "\e961";
}

.ico-start2 .path1:before {
  content: "\e962";
  color: rgb(0, 0, 0);
}

.ico-start2 .path2:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ico-stolen_mode .path1:before {
  content: "\e964";
  color: rgb(0, 0, 0);
}

.ico-stolen_mode .path2:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ico-stolen:before {
  content: "\e966";
}

.ico-subscrip:before {
  content: "\e967";
}

.ico-settings_document .path1:before {
  content: "\e968";
  color: rgb(0, 0, 0);
}

.ico-settings_document .path2:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ico-service-activation:before {
  content: "\e96a";
}

.ico-privacy:before {
  content: "\e96b";
}

.ico-remote-command:before {
  content: "\e96e";
}

.ico-print:before {
  content: "\e96f";
}

.ico-privacy_mode .path1:before {
  content: "\e970";
  color: rgb(0, 0, 0);
}

.ico-privacy_mode .path2:before {
  content: "\e971";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ico-perfortmance:before {
  content: "\e972";
}

.ico-no_climate:before {
  content: "\e973";
}

.ico-map_car-pin .path1:before {
  content: "\e974";
  color: rgb(0, 0, 0);
}

.ico-map_car-pin .path2:before {
  content: "\e975";
  margin-left: -1em;
}

.ico-map_carposition .path1:before {
  content: "\e976";
  color: rgb(0, 0, 0);
}

.ico-map_carposition .path2:before {
  content: "\e977";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ico-map_goto:before {
  content: "\e978";
}

.ico-map_maposition:before {
  content: "\e979";
}

.ico-map_myposition .path1:before {
  content: "\e97a";
  color: rgb(0, 0, 0);
}

.ico-map_myposition .path2:before {
  content: "\e97b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ico-map_myposition .path3:before {
  content: "\e97c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ico-map_nord .path1:before {
  content: "\e97d";
  color: rgb(0, 0, 0);
}

.ico-map_nord .path2:before {
  content: "\e97e";
  margin-left: -1em;
  color: none;
}

.ico-map_nord .path3:before {
  content: "\e97f";
  margin-left: -1em;
  color: none;
}

.ico-map_nord .path4:before {
  content: "\e980";
  margin-left: -1em;
  color: none;
}

.ico-map_nord .path5:before {
  content: "\e981";
  margin-left: -1em;
  color: none;
}

.ico-map_nord .path6:before {
  content: "\e982";
  margin-left: -1em;
  color: none;
}

.ico-map_nord .path7:before {
  content: "\e983";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ico-map_players .path1:before {
  content: "\e984";
  color: rgb(0, 0, 0);
}

.ico-map_players .path2:before {
  content: "\e985";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ico-map_players .path3:before {
  content: "\e986";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ico-map_refresh .path1:before {
  content: "\e987";
  color: rgb(0, 0, 0);
}

.ico-map_refresh .path2:before {
  content: "\e988";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ico-map_zoom .path1:before {
  content: "\e989";
  color: rgb(0, 0, 0);
}

.ico-map_zoom .path2:before {
  content: "\e98a";
  margin-left: -0.5068359375em;
  color: rgb(255, 255, 255);
}

.ico-map_zoom .path3:before {
  content: "\e98b";
  margin-left: -0.5068359375em;
  color: rgb(255, 255, 255);
}

.ico-map_zoom .path4:before {
  content: "\e98c";
  margin-left: -0.5068359375em;
  color: none;
}

.ico-mobile_number:before {
  content: "\e98d";
}

.ico-light2 .path1:before {
  content: "\e98e";
  color: rgb(0, 0, 0);
}

.ico-light2 .path2:before {
  content: "\e98f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ico-lights-solid:before {
  content: "\e990";
}

.ico-lightsbutton .path1:before {
  content: "\e991";
}

.ico-lightsbutton .path2:before {
  content: "\e992";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.ico-dealer:before {
  content: "\e993";
}

.ico-download:before {
  content: "\e994";
}

.ico-ecoc_acceleration:before {
  content: "\e995";
}

.ico-ecoc_calendar:before {
  content: "\e996";
}

.ico-ecoc_deceleration:before {
  content: "\e997";
}

.ico-ecoc_shifting:before {
  content: "\e998";
}

.ico-ecoc_speed:before {
  content: "\e999";
}

.ico-contact-general:before {
  content: "\e99a";
}

.ico-contact-mail-circle .path1:before {
  content: "\e99b";
  color: rgb(0, 0, 0);
}

.ico-contact-mail-circle .path2:before {
  content: "\e99c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ico-contact-mail-circle .path3:before {
  content: "\e99d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ico-contact-mail-circle .path4:before {
  content: "\e99e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ico-contact-mail-circle .path5:before {
  content: "\e99f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ico-contact-mail:before {
  content: "\e9a0";
}

.ico-contact-message:before {
  content: "\e9a1";
}

.ico-contact:before {
  content: "\e9a2";
}

.ico-controls-blocked .path1:before {
  content: "\e9a3";
  color: rgb(0, 0, 0);
}

.ico-controls-blocked .path2:before {
  content: "\e9a4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ico-controls-remote-command .path1:before {
  content: "\e9a5";
  color: rgb(0, 0, 0);
}

.ico-controls-remote-command .path2:before {
  content: "\e9a6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ico-contact-corresponce .path1:before {
  content: "\e9a7";
  color: rgb(0, 0, 0);
}

.ico-contact-corresponce .path2:before {
  content: "\e9a8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ico-contact-assistance:before {
  content: "\e9a9";
}

.ico-contact-assistance-circle .path1:before {
  content: "\e9aa";
  color: rgb(0, 0, 0);
}

.ico-contact-assistance-circle .path2:before {
  content: "\e9ab";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ico-contact-assistance-circle .path3:before {
  content: "\e9ac";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ico-contact-assistance-circle .path4:before {
  content: "\e9ad";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ico-contact-assistance-circle .path5:before {
  content: "\e9ae";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ico-climate:before {
  content: "\e9af";
}

.ico-chatbot:before {
  content: "\e9b0";
}

.ico-chatbot-close:before {
  content: "\e9b1";
}

.ico-vehicle-info_fuel-level:before {
  content: "\e9b2";
}

.ico-car_privacy:before {
  content: "\e9b3";
}

.ico-cancel:before {
  content: "\e9b4";
}

.ico-billing:before {
  content: "\e9b5";
}

.ico-alerts_ok:before {
  content: "\e9b6";
}

.ico-alerts_failed:before {
  content: "\e9b7";
}

.ico-alert_discared:before {
  content: "\e9b8";
}

.ico-upload:before {
  content: "\e902";
  color: #000;
}

.ico-security:before {
  content: "\e93e";
  color: #000;
}

.ico-mailing:before {
  content: "\e93f";
  color: #000;
}

.ico-inactive {
  position: relative;
}

.ico-inactive:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\e940";
  color: rgb(255, 255, 255);
}

.ico-inactive:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "\e941";
  color: rgb(231, 0, 0);
}

.ico-inactive.ico-red-x-white {
  &:before {
    color: $red;
  }

  &:after {
    color: $white;
  }
}

.ico-car {
  position: relative;
  padding-right: 1.2em;
  padding-bottom: 1.2em;
}

// @if ($brand == 'maserati'){
//   .ico-car:before {
//     position: absolute;
//     top:0;
//     left: 0;
//     content: "\e9c9";
//     color: rgb(0, 0, 0);
//   }
// } @else {
.ico-car:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\e942";
  color: rgb(0, 0, 0);
}

.ico-car:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "\e943";
  color: rgb(255, 255, 255);
}

// }

.ico-alert-round {
  position: relative;
}

.ico-alert-round:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\e944";
  color: rgb(240, 34, 34);
}

.ico-alert-round:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "\e945";
  color: $secondary-color;
}

.ico-account:before {
  content: "\e948" !important;
  color: #000;
}

.ico-check {
  position: relative;
}

.ico-check:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\e949";
}

.ico-check:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "\e94a";
}

.ico-zero:before {
  content: "\e94f";
}

.ico-expiredtime:before {
  content: "\e93d";
}

.ico-wifi:before {
  content: "\e932";
}

.ico-services-remote:before {
  content: "\e933";
}

.ico-services-navigation:before {
  content: "\e934";
}

.ico-services-car:before {
  content: "\e935";
}

.ico-services-assistant:before {
  content: "\e936";
}

.ico-service-myecharge:before {
  content: "\e9c4";
}

.ico-send-email:before {
  content: "\e937";
}

.ico-oilchange:before {
  content: "\e938";
}

.ico-notification-copy:before {
  content: "\e939";
}

.ico-mulfunction:before {
  content: "\e93a";
}

.ico-loading:before {
  content: "\e93b";
}

.ico-eletronic:before {
  content: "\e93c";
}

.ico-close:before {
  content: "\e931";
}

.ico-notification-full:before {
  content: "\e930";
}

.ico-add:before {
  content: "\e903";
}

.ico-add-empty:before {
  content: "\e90d";
}

.ico-add1:before {
  content: "\e90e";
}

.ico-arrow-right:before {
  content: "\e90f";
}

.ico-audio:before {
  content: "\e910";
}

.ico-call:before {
  content: "\e911";
}

.ico-call-empty:before {
  content: "\e912";
}

.ico-date:before {
  content: "\e913";
}

.ico-ecodrive:before {
  content: "\e914";
}

.ico-edit:before {
  content: "\e915";
}

.ico-edit-round:before {
  content: "\e916";
}

.ico-enlarge:before {
  content: "\e917";
}

.ico-error:before {
  content: "\e918";
}

.ico-exclamation:before {
  content: "\e919";
}

.ico-facebook:before {
  content: "\e91a";
}

.ico-fuel:before {
  content: "\e91b";
}

.ico-google:before {
  content: "\e91c";
}

.ico-instagram:before {
  content: "\e91d";
}

.ico-locker:before {
  content: "\e91e";
}

.ico-mycar:before {
  content: "\e91f";
}

.ico-navigation:before {
  content: "\e920";
}

.ico-notification:before {
  content: "\e921";
}

.ico-notifications:before {
  content: "\e922";
}

.ico-offer:before {
  content: "\e923";
}

.ico-ok:before {
  content: "\e924";
}

.ico-pay:before {
  content: "\e925";
}

.ico-play:before {
  content: "\e926";
}

.ico-privacy-notification:before {
  content: "\e927";
}

.ico-search:before {
  content: "\e928";
}

.ico-service:before {
  content: "\e929";
}

.ico-soft:before {
  content: "\e92a";
}

.ico-suggested .path1:before {
  content: "\e92b";
  color: rgb(0, 0, 0);
}

.ico-suggested .path2:before {
  content: "\e9bc";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.ico-support:before {
  content: "\e92c";
}

.ico-twitter:before {
  content: "\e92d";
}

.ico-user:before {
  content: "\e92e";
}

.ico-youtube:before {
  content: "\e92f";
}

.ico-alert:before {
  content: "\e900";
}

.ico-document:before {
  content: "\e904";
}

.ico-eye:before {
  content: "\e905";
}

.ico-info {
  position: relative;
}

.ico-info:before {
  content: "\e949";
}

.ico-info:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "\e906";
  color: #fff;
}

.ico-language:before {
  content: "\e907";
}

.ico-pin:before {
  content: "\e908";
}

.ico-security-question:before {
  content: "\e909";
}

.ico-chevron-down:before {
  content: "\e901";
}

.ico-chevron-left:before {
  content: "\e90a";
}

.ico-chevron-up:before {
  content: "\e90b";
}

.ico-chevron-right:before {
  content: "\e90c";
}

.ico-headunit:before {
  content: "\e9c6";
}

.ico-offroad-front:before {
  content: "\e9cf";
}

.ico-services-path:before {
  content: "\e9d2";
}

.ico-offroad-side:before {
  content: "\e9d3";
}

.ico-altimeter:before {
  content: "\e9d4";
}

.ico-green-star:before {
  content: "\e9d5";
}

.ico-virtual-assistant-balloon:before {
  content: "\e9d6";
}

.ico-virtual-assistant-shield:before {
  content: "\e9d7";
}

.ico-account-linking:before {
  content: "\e9d8";
}

.ico-letter-alert:before {
  content: "\e9d9";
}

.ico-arrow-left:before {
  content: "\e9da";
}

.ico-privacy-notification-message:before {
  content: "\e9db";
}

.ico-cta-unlink:before {
  content: "\e9dc";
}

.ico-delivery:before {
  content: "\e9dd";
}

.ico-settings-date:before {
  content: "\e9de";
}

.ico-cloud-wheel:before {
  content: "\e9df";
}

.ico-heated-wheel:before {
  content: "\e9e0";
}

.ico-heated-seat:before {
  content: "\e9e1";
}

.ico-vented-seat:before {
  content: "\e9e2";
}

.ico-cloud-settings:before {
  content: "\e9e3";
}

.ico-icons-suggested:before {
  content: "\e9e4";
}

.ico-battery:before {
  content: "\e9e5";
}

.ico-batterycharge:before {
  content: "\e9e6";
}

.ico-SiriusXM-Guardian:before {
  content: "\e9e7";
}

.ico-map-updates:before {
  content: "\e9e8";
}

.ico-offer-black:before {
  content: "\e9e9";
}

.ico-payment-method:before {
  content: "\e9ea";
}

.ico-cart:before {
  content: "\e9eb";
}

.ico-discount:before {
  content: "\e9ec";
}

.ico-discount-line:before {
  content: "\e9ed";
}

.ico-avatar:before {
  content: "\e9ee";
}

.ico-round-check:before {
  content: "\e9ef";
}

.ico-round-times:before {
  content: "\e9f0";
}

.ico-round-pending:before {
  content: "\e9f1";
}

.ico-subscription-messages:before {
  content: "\e9f2";
}

.ico-payment-accounts:before {
  content: "\e9f3";
}

.ico-website-preferences_new:before {
  content: "\e9f4";
}

.ico-efs:before {
  content: "\e9f5";
}

.ico-icons-services-vhr:before {
  content: "\e9f6";
}

.ico-icons-refresh:before {
  content: "\e9f7";
}

.ico-icons-map-center-vehicle:before {
  content: "\e9f8";
}

.ico-icons-mapp-center:before {
  content: "\e9f9";
}

.ico-linkedin:before {
  content: "\e9fa";
}

.ico-crown:before {
  content: "\e9fb";
}

.ico-emergency:before {
  content: "\e9fc";
}

.ico-security2:before {
  content: "\e9fd";
}

.ico-combo2:before {
  content: "\e9fe";
}

.ico-control:before {
  content: "\e9ff";
}

.ico-navigation2:before {
  content: "\ea00";
}

.ico-door-lock:before {
  content: "\ea01";
}

.ico-door-unlock:before {
  content: "\ea02";
}

.ico-trunk-lock:before {
  content: "\ea03";
}

.ico-trunk-unlock:before {
  content: "\ea04";
}

.ico-myalert:before {
  content: "\ea05";
}

.ico-bubble:before {
  content: "\ea0a";
}

.ico-dealer_not_certified:before {
  content: "\ea06";
}

.ico-dealer_certified .path1:before {
  content: "\ea07";
  color: #000;
}

.ico-dealer_certified .path2:before {
  content: "\ea08";
  color: #000;
  margin-left: -1.5em;
}

.ico-dealer_certified .path3:before {
  content: "\ea09";
  color: #fff;
  margin-left: -1.5em;
}

.ico-bareleft-waypoint:before {
  content: "\ea0b";
}

.ico-bareright-waypoint:before {
  content: "\ea0c";
}

.ico-camping-waypoint:before {
  content: "\ea0d";
}

.ico-deadend-waypoint:before {
  content: "\ea0e";
}

.ico-hardleft-waypoint:before {
  content: "\ea0f";
}

.ico-hardright-waypoint:before {
  content: "\ea10";
}

.ico-iconic-waypoint:before {
  content: "\ea11";
}

.ico-mud-waypoint:before {
  content: "\ea12";
}

.ico-pitch:before {
  content: "\ea13";
}

.ico-rock-waypoint:before {
  content: "\ea14";
}

.ico-roll:before {
  content: "\ea15";
}

.ico-routeclosure-waypoint:before {
  content: "\ea16";
}

.ico-sand-waypoint:before {
  content: "\ea17";
}

.ico-scenic-waypoint:before {
  content: "\ea18";
}

.ico-slow-waypoint:before {
  content: "\ea19";
}

.ico-stagingarea-waypoint:before {
  content: "\ea1a";
}

.ico-steepascent-waypoint:before {
  content: "\ea1b";
}

.ico-steepdescent-waypoint:before {
  content: "\ea1c";
}

.ico-trailhead-waypoint:before {
  content: "\ea1d";
}

.ico-water-waypoint:before {
  content: "\ea1e";
}

// COLORS
.ico-add1:before,
.ico-account:before,
.ico-send-email:before,
.ico-lightsbutton .path1:before,
.ico-map_car-pin .path2:before,
.ico-locker:before {
  color: $black;
}

.ico-check:before {
  // @if($brand == 'fiat-latam' or $brand == 'fiatprofessional') {
  //   color: $green !important;
  // }
  // @else {
  //   color: $success;
  // }
  color: $success;
}

.ico-check:after {
  color: $white;

  // @if($brand == 'fiatprofessional') {
  //   color: #2c2c2c;
  // }
}