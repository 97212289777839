// #region Roboto
@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/Roboto-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

// #endregion

// #region ProximaNova
@font-face {
  font-family: "ProximaNova";
  src: url("../../assets/fonts/Proxima-Nova-Regular.ttf") format("truetype"),
    url("../../assets/fonts/Proxima-Nova-Regular.woff") format("woff"),
    url("../../assets/fonts/Proxima-Nova-Regular.svg") format("svg"),
    url("../../assets/fonts/Proxima-Nova-Regular.eot");
  font-weight: normal;
}

@font-face {
  font-family: "ProximaNova";
  src: url("../../assets/fonts/Proxima-Nova-Bold.ttf") format("truetype"),
    url("../../assets/fonts/Proxima-Nova-Bold.woff") format("woff"),
    url("../../assets/fonts/Proxima-Nova-Bold.svg") format("svg"),
    url("../../assets/fonts/Proxima-Nova-Bold.eot");
  font-weight: bold;
}

// #endregion

// #region Montserrat
@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
}

// #endregion

// #region Poppins
@font-face {
  font-family: 'Poppins';
  src: url('../../assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
}

// #endregion

// #region Sequel100Black45
@font-face {
  font-family: 'Sequel100Black45';
  src: url('../../assets/fonts/Sequel100Black45.ttf') format('truetype');
  font-weight: normal;
}

// #endregion

// #region PSAGroupedHMISans
@font-face {
  font-family: 'PSAGroupedHMISans';
  src: url('../../assets/fonts/PSAGroupeHMISans-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'PSAGroupedHMISans';
  src: url('../../assets/fonts/PSAGroupeHMISans-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'PSAGroupedHMISans';
  src: url('../../assets/fonts/PSAGroupeHMISans-Light.ttf') format('truetype');
  font-weight: lighter;
}

// #endregion

//#region ABARTH
@font-face {
  font-family: "Abarth";
  font-weight: bold;
  src: url("../../assets/fonts/ABARTH-Super.otf") format("opentype");
}

@font-face {
  font-family: "Abarth";
  font-weight: normal;
  src: url("../../assets/fonts/ABARTH-Medium.otf") format("opentype");
}

//#endregion