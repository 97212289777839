:root {
  // --brand-font-family: inherit;
  --brand-font-family: 'ProximaNova';
  --default-font-family: 'Roboto';
  // --h-font-family: var(--brand-font-family)
  // --btn-font-family: var(--default-font-family);
  // --stitle-font-family: var(--default-font-family);
  // --body-font-family: var(--default-font-family);
  // --sbody-font-family: var(--default-font-family);
  // --caption1-font-family: var(--default-font-family);
  // --caption2-font-family: var(--default-font-family);
}

$h1-md-font-size: 3rem;
$h1-xs-font-size: 1.875rem;
$h1-md-line-height: 1.083;
$h1-xs-line-height: 1.2;

$h2-md-font-size: 1.875rem;
$h2-xs-font-size: 1.5rem;
$h2-md-line-height: 1.2;
$h2-xs-line-height: 1.17;

$h3-md-line-height: 1.14;
$h3-xs-line-height: 1.17;
$h3-md-font-size: 1.75rem;
$h3-xs-font-size: 1.5rem;

$h4-md-font-size: 1.5rem;
$h4-xs-font-size: 1.25rem;
$h4-md-line-height: 1.17;
$h4-xs-line-height: 1.2;

$font-subtitle-font-size: 1rem; // 16px
$font-subtitle-line-height: 1.5; // 24px
$font-subtitle-font-weight: 700; // bold

// $body-font-family: $default-font;
$body-font-size: 1rem; // 16px
$body-line-height: 1.5; // 24px
$body-font-weight: 400;

$sub-body-font-size: 0.875rem; // 14px
$sub-body-line-height: 1.4; // 20px
$sub-body-font-weight: 500; // medium

$caption-1-font-size: 0.75rem; // 12px
$caption-1-line-height: 1.3; // 16px

$caption-2-font-size: 0.75rem; // 12px
$caption-2-line-height: 1.3; // 16px
$caption-2-font-style: italic;

$overline-font-size: 0.625rem; // 10px
$overline-line-height: 1.6; // 16px
$overline-font-weight: 400;

$button-text-transform: none;

// $button-font-family: $default-font;
$button-font-size: 0.875rem; // 14px
$button-line-height: 1.4; // 20px
$button-font-weight: 700; // bold

// @mixin font-family-roboto {
//   font-family: "Roboto", sans-serif;
// }

// @mixin font-family-roboto-medium {
//   font-family: "Roboto", sans-serif;
//   font-weight: 500;
// }

// @mixin font-family-roboto-bold {
//   font-family: "Roboto", sans-serif;
//   font-weight: bold;
// }

// $heading-font-family: var(--h-font-family);
// $button-font-family: var(--btn-font-family);
// $stitle-font-family: var(--stitle-font-family);
// $body-font-family: var(--body-font-family);
// $sbody-font-family: var(--sbody-font-family);
// $caption1-font-family: var(--caption1-font-family);
// $caption2-font-family: var(--caption2-font-family);

h1 {
  font-size: $h1-md-font-size;
  line-height: $h1-md-line-height;
  font-family: var(--brand-font-family);
  // font-family: $heading-font-family;
  font-weight: 700;

  @include media-breakpoint-down(sm) {
    font-size: $h1-xs-font-size;
    line-height: $h1-xs-line-height;
  }
}

h2 {
  font-size: $h2-md-font-size;
  line-height: $h2-md-line-height;
  font-family: var(--brand-font-family);
  // font-family: $heading-font-family;
  font-weight: 700;

  @include media-breakpoint-down(sm) {
    font-size: $h2-xs-font-size;
    line-height: $h2-xs-line-height;
  }
}

h3 {
  font-size: $h3-md-font-size;
  line-height: $h3-md-line-height;
  font-family: var(--brand-font-family);
  // font-family: $heading-font-family;

  @include media-breakpoint-down(sm) {
    font-size: $h3-xs-font-size;
    line-height: $h3-xs-line-height;
  }
}

h4 {
  font-size: $h4-md-font-size;
  line-height: $h4-md-line-height;
  font-family: var(--brand-font-family);
  // font-family: $heading-font-family;
  font-weight: 700;

  @include media-breakpoint-down(sm) {
    font-size: $h4-xs-font-size;
    line-height: $h4-xs-line-height;
  }
}

button {
  font-size: $button-font-size;
  line-height: $button-line-height;
  font-weight: $button-font-weight;
  font-family: var(--default-font-family);
  // font-family: $button-font-family;
}

.font-subtitle {
  font-size: $font-subtitle-font-size;
  line-height: $font-subtitle-line-height;
  font-weight: $font-subtitle-font-weight;
  font-family: var(--default-font-family);
  // font-family: $stitle-font-family;
}

.font-body {
  font-size: $body-font-size;
  line-height: $body-line-height;
  font-weight: $body-font-weight;
  font-family: var(--default-font-family);
  // font-family: $body-font-family;
}

.font-sub-body {
  font-size: $sub-body-font-size;
  line-height: $sub-body-line-height;
  font-weight: $sub-body-font-weight;
  font-family: var(--default-font-family);
  // font-family: $sbody-font-family;
}

.font-caption-1 {
  font-size: $caption-1-font-size;
  line-height: $caption-1-line-height;
  font-family: var(--default-font-family);
  // font-family: $caption1-font-family;
}

.font-caption-2 {
  font-size: $caption-2-font-size;
  line-height: $caption-2-font-size;
  font-style: $caption-2-font-style;
  font-family: var(--default-font-family);
  // font-family: $caption2-font-family;
}